import { default as positionsRMOeSh6DKzMeta } from "/home/techstaack/inj/injective-dex/pages/account/positions.vue?macro=true";
import { default as fee_45discountsGwHC7U0VQiMeta } from "/home/techstaack/inj/injective-dex/pages/fee-discounts.vue?macro=true";
import { default as _91slug_93PlAxWd0R4FMeta } from "/home/techstaack/inj/injective-dex/pages/futures/[slug].vue?macro=true";
import { default as indexlLXEeBYYaxMeta } from "/home/techstaack/inj/injective-dex/pages/futures/index.vue?macro=true";
import { default as futuresRtGw7xGy39Meta } from "/home/techstaack/inj/injective-dex/pages/futures.vue?macro=true";
import { default as _91guild_93d6DDLCm8uIMeta } from "/home/techstaack/inj/injective-dex/pages/guild/[guild].vue?macro=true";
import { default as guilds31cRHX16XRMeta } from "/home/techstaack/inj/injective-dex/pages/guilds.vue?macro=true";
import { default as indexUSEmE7RmlwMeta } from "/home/techstaack/inj/injective-dex/pages/index.vue?macro=true";
import { default as institutional2BBQYDMz2XMeta } from "/home/techstaack/inj/injective-dex/pages/institutional.vue?macro=true";
import { default as liquidity_45provision86iftuImsdMeta } from "/home/techstaack/inj/injective-dex/pages/liquidity-provision.vue?macro=true";
import { default as campaignzs22mCkf8qMeta } from "/home/techstaack/inj/injective-dex/pages/lp-rewards/campaign.vue?macro=true";
import { default as dashboardpmZbtUcvvSMeta } from "/home/techstaack/inj/injective-dex/pages/lp-rewards/dashboard.vue?macro=true";
import { default as indexbaBQrHymmrMeta } from "/home/techstaack/inj/injective-dex/pages/lp-rewards/index.vue?macro=true";
import { default as lp_45rewards4jg7saNlLEMeta } from "/home/techstaack/inj/injective-dex/pages/lp-rewards.vue?macro=true";
import { default as maintenanceCRipMSOqRiMeta } from "/home/techstaack/inj/injective-dex/pages/maintenance.vue?macro=true";
import { default as marketssQ7gVIqa78Meta } from "/home/techstaack/inj/injective-dex/pages/markets.vue?macro=true";
import { default as playgroundrSAEpOKdg7Meta } from "/home/techstaack/inj/injective-dex/pages/playground.vue?macro=true";
import { default as pnl_45leaderboard1fMvkimZw8Meta } from "/home/techstaack/inj/injective-dex/pages/pnl-leaderboard.vue?macro=true";
import { default as indexTSTNnt5KotMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/balances/index.vue?macro=true";
import { default as indexiO3fBOuP5HMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/history/funding-payments/index.vue?macro=true";
import { default as indexpuP1CKMacIMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/history/swap/index.vue?macro=true";
import { default as indexzKOP9LNyb8Meta } from "/home/techstaack/inj/injective-dex/pages/portfolio/history/wallet/index.vue?macro=true";
import { default as index5rQlRGtUPXMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/index.vue?macro=true";
import { default as futures_45gridLOjGZ3SzIxMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures-grid.vue?macro=true";
import { default as index8L2fRXGKVOMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures/index.vue?macro=true";
import { default as order_45historysCGrXxyJfSMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures/order-history.vue?macro=true";
import { default as trade_45historyeaUvZ2sx8xMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures/trade-history.vue?macro=true";
import { default as triggersQ9ibZflWyTMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures/triggers.vue?macro=true";
import { default as futurestruHCx0VcdMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures.vue?macro=true";
import { default as historyQgb6qimbgbMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot-grid/history.vue?macro=true";
import { default as index958JAZi3VAMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot-grid/index.vue?macro=true";
import { default as spot_45gridPDnnIKMGANMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot-grid.vue?macro=true";
import { default as index4fQ7UZHmePMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot/index.vue?macro=true";
import { default as order_45historyFIq3JszGCHMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot/order-history.vue?macro=true";
import { default as trade_45historyGDdV0zW8C7Meta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot/trade-history.vue?macro=true";
import { default as spotEiR785DR4XMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot.vue?macro=true";
import { default as indexTSKp4K5MQoMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/positions/index.vue?macro=true";
import { default as granter06KobdTxoPMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/settings/authz/granter.vue?macro=true";
import { default as indexc3HSTYZnA0Meta } from "/home/techstaack/inj/injective-dex/pages/portfolio/settings/authz/index.vue?macro=true";
import { default as authzI8WCma5tjIMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/settings/authz.vue?macro=true";
import { default as autosignYg2HMNDw0IMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/settings/autosign.vue?macro=true";
import { default as indexlZHcTODY2rMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/settings/index.vue?macro=true";
import { default as preferencesv65SYMzUonMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/settings/preferences.vue?macro=true";
import { default as indexlYpCxFelXfMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio/subaccounts/index.vue?macro=true";
import { default as portfolio7KitqVzeKjMeta } from "/home/techstaack/inj/injective-dex/pages/portfolio.vue?macro=true";
import { default as _91slug_93q6aZri1SdUMeta } from "/home/techstaack/inj/injective-dex/pages/spot/[slug].vue?macro=true";
import { default as indexnhXLb5UHkmMeta } from "/home/techstaack/inj/injective-dex/pages/spot/index.vue?macro=true";
import { default as spotmZGMHiTO40Meta } from "/home/techstaack/inj/injective-dex/pages/spot.vue?macro=true";
import { default as swap8StgaGVU2yMeta } from "/home/techstaack/inj/injective-dex/pages/swap.vue?macro=true";
import { default as termsk0lhCrK4gIMeta } from "/home/techstaack/inj/injective-dex/pages/terms.vue?macro=true";
import { default as trade_45and_45earnAiOg9smCnYMeta } from "/home/techstaack/inj/injective-dex/pages/trade-and-earn.vue?macro=true";
import { default as historyALp1GbSt3vMeta } from "/home/techstaack/inj/injective-dex/pages/trading-bots/liquidity-bots/spot/history.vue?macro=true";
import { default as indexEPVPF9SsyqMeta } from "/home/techstaack/inj/injective-dex/pages/trading-bots/liquidity-bots/spot/index.vue?macro=true";
import { default as liquidity_45botszbBMafbZdVMeta } from "/home/techstaack/inj/injective-dex/pages/trading-bots/liquidity-bots.vue?macro=true";
export default [
  {
    name: positionsRMOeSh6DKzMeta?.name ?? "account-positions",
    path: positionsRMOeSh6DKzMeta?.path ?? "/account/positions",
    meta: positionsRMOeSh6DKzMeta || {},
    alias: positionsRMOeSh6DKzMeta?.alias || [],
    redirect: positionsRMOeSh6DKzMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/account/positions.vue").then(m => m.default || m)
  },
  {
    name: fee_45discountsGwHC7U0VQiMeta?.name ?? "fee-discounts",
    path: fee_45discountsGwHC7U0VQiMeta?.path ?? "/fee-discounts",
    meta: fee_45discountsGwHC7U0VQiMeta || {},
    alias: fee_45discountsGwHC7U0VQiMeta?.alias || [],
    redirect: fee_45discountsGwHC7U0VQiMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/fee-discounts.vue").then(m => m.default || m)
  },
  {
    name: futuresRtGw7xGy39Meta?.name ?? undefined,
    path: futuresRtGw7xGy39Meta?.path ?? "/futures",
    meta: futuresRtGw7xGy39Meta || {},
    alias: futuresRtGw7xGy39Meta?.alias || [],
    redirect: futuresRtGw7xGy39Meta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/futures.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93PlAxWd0R4FMeta?.name ?? "futures-slug",
    path: _91slug_93PlAxWd0R4FMeta?.path ?? ":slug()",
    meta: _91slug_93PlAxWd0R4FMeta || {},
    alias: _91slug_93PlAxWd0R4FMeta?.alias || [],
    redirect: _91slug_93PlAxWd0R4FMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/futures/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlLXEeBYYaxMeta?.name ?? "futures",
    path: indexlLXEeBYYaxMeta?.path ?? "",
    meta: indexlLXEeBYYaxMeta || {},
    alias: indexlLXEeBYYaxMeta?.alias || [],
    redirect: indexlLXEeBYYaxMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/futures/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91guild_93d6DDLCm8uIMeta?.name ?? "guild-guild",
    path: _91guild_93d6DDLCm8uIMeta?.path ?? "/guild/:guild()",
    meta: _91guild_93d6DDLCm8uIMeta || {},
    alias: _91guild_93d6DDLCm8uIMeta?.alias || [],
    redirect: _91guild_93d6DDLCm8uIMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/guild/[guild].vue").then(m => m.default || m)
  },
  {
    name: guilds31cRHX16XRMeta?.name ?? "guilds",
    path: guilds31cRHX16XRMeta?.path ?? "/guilds",
    meta: guilds31cRHX16XRMeta || {},
    alias: guilds31cRHX16XRMeta?.alias || [],
    redirect: guilds31cRHX16XRMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/guilds.vue").then(m => m.default || m)
  },
  {
    name: indexUSEmE7RmlwMeta?.name ?? "index",
    path: indexUSEmE7RmlwMeta?.path ?? "/",
    meta: indexUSEmE7RmlwMeta || {},
    alias: indexUSEmE7RmlwMeta?.alias || [],
    redirect: indexUSEmE7RmlwMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/index.vue").then(m => m.default || m)
  },
  {
    name: institutional2BBQYDMz2XMeta?.name ?? "institutional",
    path: institutional2BBQYDMz2XMeta?.path ?? "/institutional",
    meta: institutional2BBQYDMz2XMeta || {},
    alias: institutional2BBQYDMz2XMeta?.alias || [],
    redirect: institutional2BBQYDMz2XMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/institutional.vue").then(m => m.default || m)
  },
  {
    name: liquidity_45provision86iftuImsdMeta?.name ?? "liquidity-provision",
    path: liquidity_45provision86iftuImsdMeta?.path ?? "/liquidity-provision",
    meta: liquidity_45provision86iftuImsdMeta || {},
    alias: liquidity_45provision86iftuImsdMeta?.alias || [],
    redirect: liquidity_45provision86iftuImsdMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/liquidity-provision.vue").then(m => m.default || m)
  },
  {
    name: lp_45rewards4jg7saNlLEMeta?.name ?? undefined,
    path: lp_45rewards4jg7saNlLEMeta?.path ?? "/lp-rewards",
    meta: lp_45rewards4jg7saNlLEMeta || {},
    alias: lp_45rewards4jg7saNlLEMeta?.alias || [],
    redirect: lp_45rewards4jg7saNlLEMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/lp-rewards.vue").then(m => m.default || m),
    children: [
  {
    name: campaignzs22mCkf8qMeta?.name ?? "lp-rewards-campaign",
    path: campaignzs22mCkf8qMeta?.path ?? "campaign",
    meta: campaignzs22mCkf8qMeta || {},
    alias: campaignzs22mCkf8qMeta?.alias || [],
    redirect: campaignzs22mCkf8qMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/lp-rewards/campaign.vue").then(m => m.default || m)
  },
  {
    name: dashboardpmZbtUcvvSMeta?.name ?? "lp-rewards-dashboard",
    path: dashboardpmZbtUcvvSMeta?.path ?? "dashboard",
    meta: dashboardpmZbtUcvvSMeta || {},
    alias: dashboardpmZbtUcvvSMeta?.alias || [],
    redirect: dashboardpmZbtUcvvSMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/lp-rewards/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexbaBQrHymmrMeta?.name ?? "lp-rewards",
    path: indexbaBQrHymmrMeta?.path ?? "",
    meta: indexbaBQrHymmrMeta || {},
    alias: indexbaBQrHymmrMeta?.alias || [],
    redirect: indexbaBQrHymmrMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/lp-rewards/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: maintenanceCRipMSOqRiMeta?.name ?? "maintenance",
    path: maintenanceCRipMSOqRiMeta?.path ?? "/maintenance",
    meta: maintenanceCRipMSOqRiMeta || {},
    alias: maintenanceCRipMSOqRiMeta?.alias || [],
    redirect: maintenanceCRipMSOqRiMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: marketssQ7gVIqa78Meta?.name ?? "markets",
    path: marketssQ7gVIqa78Meta?.path ?? "/markets",
    meta: marketssQ7gVIqa78Meta || {},
    alias: marketssQ7gVIqa78Meta?.alias || [],
    redirect: marketssQ7gVIqa78Meta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/markets.vue").then(m => m.default || m)
  },
  {
    name: playgroundrSAEpOKdg7Meta?.name ?? "playground",
    path: playgroundrSAEpOKdg7Meta?.path ?? "/playground",
    meta: playgroundrSAEpOKdg7Meta || {},
    alias: playgroundrSAEpOKdg7Meta?.alias || [],
    redirect: playgroundrSAEpOKdg7Meta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: pnl_45leaderboard1fMvkimZw8Meta?.name ?? "pnl-leaderboard",
    path: pnl_45leaderboard1fMvkimZw8Meta?.path ?? "/pnl-leaderboard",
    meta: pnl_45leaderboard1fMvkimZw8Meta || {},
    alias: pnl_45leaderboard1fMvkimZw8Meta?.alias || [],
    redirect: pnl_45leaderboard1fMvkimZw8Meta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/pnl-leaderboard.vue").then(m => m.default || m)
  },
  {
    name: portfolio7KitqVzeKjMeta?.name ?? undefined,
    path: portfolio7KitqVzeKjMeta?.path ?? "/portfolio",
    meta: portfolio7KitqVzeKjMeta || {},
    alias: portfolio7KitqVzeKjMeta?.alias || [],
    redirect: portfolio7KitqVzeKjMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio.vue").then(m => m.default || m),
    children: [
  {
    name: indexTSTNnt5KotMeta?.name ?? "portfolio-balances",
    path: indexTSTNnt5KotMeta?.path ?? "balances",
    meta: indexTSTNnt5KotMeta || {},
    alias: indexTSTNnt5KotMeta?.alias || [],
    redirect: indexTSTNnt5KotMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/balances/index.vue").then(m => m.default || m)
  },
  {
    name: indexiO3fBOuP5HMeta?.name ?? "portfolio-history-funding-payments",
    path: indexiO3fBOuP5HMeta?.path ?? "history/funding-payments",
    meta: indexiO3fBOuP5HMeta || {},
    alias: indexiO3fBOuP5HMeta?.alias || [],
    redirect: indexiO3fBOuP5HMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/history/funding-payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexpuP1CKMacIMeta?.name ?? "portfolio-history-swap",
    path: indexpuP1CKMacIMeta?.path ?? "history/swap",
    meta: indexpuP1CKMacIMeta || {},
    alias: indexpuP1CKMacIMeta?.alias || [],
    redirect: indexpuP1CKMacIMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/history/swap/index.vue").then(m => m.default || m)
  },
  {
    name: indexzKOP9LNyb8Meta?.name ?? "portfolio-history-wallet",
    path: indexzKOP9LNyb8Meta?.path ?? "history/wallet",
    meta: indexzKOP9LNyb8Meta || {},
    alias: indexzKOP9LNyb8Meta?.alias || [],
    redirect: indexzKOP9LNyb8Meta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/history/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: index5rQlRGtUPXMeta?.name ?? "portfolio",
    path: index5rQlRGtUPXMeta?.path ?? "",
    meta: index5rQlRGtUPXMeta || {},
    alias: index5rQlRGtUPXMeta?.alias || [],
    redirect: index5rQlRGtUPXMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: futures_45gridLOjGZ3SzIxMeta?.name ?? "portfolio-orders-futures-grid",
    path: futures_45gridLOjGZ3SzIxMeta?.path ?? "orders/futures-grid",
    meta: futures_45gridLOjGZ3SzIxMeta || {},
    alias: futures_45gridLOjGZ3SzIxMeta?.alias || [],
    redirect: futures_45gridLOjGZ3SzIxMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures-grid.vue").then(m => m.default || m)
  },
  {
    name: futurestruHCx0VcdMeta?.name ?? undefined,
    path: futurestruHCx0VcdMeta?.path ?? "orders/futures",
    meta: futurestruHCx0VcdMeta || {},
    alias: futurestruHCx0VcdMeta?.alias || [],
    redirect: futurestruHCx0VcdMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures.vue").then(m => m.default || m),
    children: [
  {
    name: index8L2fRXGKVOMeta?.name ?? "portfolio-orders-futures",
    path: index8L2fRXGKVOMeta?.path ?? "",
    meta: index8L2fRXGKVOMeta || {},
    alias: index8L2fRXGKVOMeta?.alias || [],
    redirect: index8L2fRXGKVOMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures/index.vue").then(m => m.default || m)
  },
  {
    name: order_45historysCGrXxyJfSMeta?.name ?? "portfolio-orders-futures-order-history",
    path: order_45historysCGrXxyJfSMeta?.path ?? "order-history",
    meta: order_45historysCGrXxyJfSMeta || {},
    alias: order_45historysCGrXxyJfSMeta?.alias || [],
    redirect: order_45historysCGrXxyJfSMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures/order-history.vue").then(m => m.default || m)
  },
  {
    name: trade_45historyeaUvZ2sx8xMeta?.name ?? "portfolio-orders-futures-trade-history",
    path: trade_45historyeaUvZ2sx8xMeta?.path ?? "trade-history",
    meta: trade_45historyeaUvZ2sx8xMeta || {},
    alias: trade_45historyeaUvZ2sx8xMeta?.alias || [],
    redirect: trade_45historyeaUvZ2sx8xMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures/trade-history.vue").then(m => m.default || m)
  },
  {
    name: triggersQ9ibZflWyTMeta?.name ?? "portfolio-orders-futures-triggers",
    path: triggersQ9ibZflWyTMeta?.path ?? "triggers",
    meta: triggersQ9ibZflWyTMeta || {},
    alias: triggersQ9ibZflWyTMeta?.alias || [],
    redirect: triggersQ9ibZflWyTMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/futures/triggers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: spot_45gridPDnnIKMGANMeta?.name ?? undefined,
    path: spot_45gridPDnnIKMGANMeta?.path ?? "orders/spot-grid",
    meta: spot_45gridPDnnIKMGANMeta || {},
    alias: spot_45gridPDnnIKMGANMeta?.alias || [],
    redirect: spot_45gridPDnnIKMGANMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot-grid.vue").then(m => m.default || m),
    children: [
  {
    name: historyQgb6qimbgbMeta?.name ?? "portfolio-orders-spot-grid-history",
    path: historyQgb6qimbgbMeta?.path ?? "history",
    meta: historyQgb6qimbgbMeta || {},
    alias: historyQgb6qimbgbMeta?.alias || [],
    redirect: historyQgb6qimbgbMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot-grid/history.vue").then(m => m.default || m)
  },
  {
    name: index958JAZi3VAMeta?.name ?? "portfolio-orders-spot-grid",
    path: index958JAZi3VAMeta?.path ?? "",
    meta: index958JAZi3VAMeta || {},
    alias: index958JAZi3VAMeta?.alias || [],
    redirect: index958JAZi3VAMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot-grid/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: spotEiR785DR4XMeta?.name ?? undefined,
    path: spotEiR785DR4XMeta?.path ?? "orders/spot",
    meta: spotEiR785DR4XMeta || {},
    alias: spotEiR785DR4XMeta?.alias || [],
    redirect: spotEiR785DR4XMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot.vue").then(m => m.default || m),
    children: [
  {
    name: index4fQ7UZHmePMeta?.name ?? "portfolio-orders-spot",
    path: index4fQ7UZHmePMeta?.path ?? "",
    meta: index4fQ7UZHmePMeta || {},
    alias: index4fQ7UZHmePMeta?.alias || [],
    redirect: index4fQ7UZHmePMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot/index.vue").then(m => m.default || m)
  },
  {
    name: order_45historyFIq3JszGCHMeta?.name ?? "portfolio-orders-spot-order-history",
    path: order_45historyFIq3JszGCHMeta?.path ?? "order-history",
    meta: order_45historyFIq3JszGCHMeta || {},
    alias: order_45historyFIq3JszGCHMeta?.alias || [],
    redirect: order_45historyFIq3JszGCHMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot/order-history.vue").then(m => m.default || m)
  },
  {
    name: trade_45historyGDdV0zW8C7Meta?.name ?? "portfolio-orders-spot-trade-history",
    path: trade_45historyGDdV0zW8C7Meta?.path ?? "trade-history",
    meta: trade_45historyGDdV0zW8C7Meta || {},
    alias: trade_45historyGDdV0zW8C7Meta?.alias || [],
    redirect: trade_45historyGDdV0zW8C7Meta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/orders/spot/trade-history.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexTSKp4K5MQoMeta?.name ?? "portfolio-positions",
    path: indexTSKp4K5MQoMeta?.path ?? "positions",
    meta: indexTSKp4K5MQoMeta || {},
    alias: indexTSKp4K5MQoMeta?.alias || [],
    redirect: indexTSKp4K5MQoMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/positions/index.vue").then(m => m.default || m)
  },
  {
    name: authzI8WCma5tjIMeta?.name ?? undefined,
    path: authzI8WCma5tjIMeta?.path ?? "settings/authz",
    meta: authzI8WCma5tjIMeta || {},
    alias: authzI8WCma5tjIMeta?.alias || [],
    redirect: authzI8WCma5tjIMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/settings/authz.vue").then(m => m.default || m),
    children: [
  {
    name: granter06KobdTxoPMeta?.name ?? "portfolio-settings-authz-granter",
    path: granter06KobdTxoPMeta?.path ?? "granter",
    meta: granter06KobdTxoPMeta || {},
    alias: granter06KobdTxoPMeta?.alias || [],
    redirect: granter06KobdTxoPMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/settings/authz/granter.vue").then(m => m.default || m)
  },
  {
    name: indexc3HSTYZnA0Meta?.name ?? "portfolio-settings-authz",
    path: indexc3HSTYZnA0Meta?.path ?? "",
    meta: indexc3HSTYZnA0Meta || {},
    alias: indexc3HSTYZnA0Meta?.alias || [],
    redirect: indexc3HSTYZnA0Meta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/settings/authz/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: autosignYg2HMNDw0IMeta?.name ?? "portfolio-settings-autosign",
    path: autosignYg2HMNDw0IMeta?.path ?? "settings/autosign",
    meta: autosignYg2HMNDw0IMeta || {},
    alias: autosignYg2HMNDw0IMeta?.alias || [],
    redirect: autosignYg2HMNDw0IMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/settings/autosign.vue").then(m => m.default || m)
  },
  {
    name: indexlZHcTODY2rMeta?.name ?? "portfolio-settings",
    path: indexlZHcTODY2rMeta?.path ?? "settings",
    meta: indexlZHcTODY2rMeta || {},
    alias: indexlZHcTODY2rMeta?.alias || [],
    redirect: indexlZHcTODY2rMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/settings/index.vue").then(m => m.default || m)
  },
  {
    name: preferencesv65SYMzUonMeta?.name ?? "portfolio-settings-preferences",
    path: preferencesv65SYMzUonMeta?.path ?? "settings/preferences",
    meta: preferencesv65SYMzUonMeta || {},
    alias: preferencesv65SYMzUonMeta?.alias || [],
    redirect: preferencesv65SYMzUonMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/settings/preferences.vue").then(m => m.default || m)
  },
  {
    name: indexlYpCxFelXfMeta?.name ?? "portfolio-subaccounts",
    path: indexlYpCxFelXfMeta?.path ?? "subaccounts",
    meta: indexlYpCxFelXfMeta || {},
    alias: indexlYpCxFelXfMeta?.alias || [],
    redirect: indexlYpCxFelXfMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/portfolio/subaccounts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: spotmZGMHiTO40Meta?.name ?? undefined,
    path: spotmZGMHiTO40Meta?.path ?? "/spot",
    meta: spotmZGMHiTO40Meta || {},
    alias: spotmZGMHiTO40Meta?.alias || [],
    redirect: spotmZGMHiTO40Meta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/spot.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93q6aZri1SdUMeta?.name ?? "spot-slug",
    path: _91slug_93q6aZri1SdUMeta?.path ?? ":slug()",
    meta: _91slug_93q6aZri1SdUMeta || {},
    alias: _91slug_93q6aZri1SdUMeta?.alias || [],
    redirect: _91slug_93q6aZri1SdUMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/spot/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnhXLb5UHkmMeta?.name ?? "spot",
    path: indexnhXLb5UHkmMeta?.path ?? "",
    meta: indexnhXLb5UHkmMeta || {},
    alias: indexnhXLb5UHkmMeta?.alias || [],
    redirect: indexnhXLb5UHkmMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/spot/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: swap8StgaGVU2yMeta?.name ?? "swap",
    path: swap8StgaGVU2yMeta?.path ?? "/swap",
    meta: swap8StgaGVU2yMeta || {},
    alias: swap8StgaGVU2yMeta?.alias || [],
    redirect: swap8StgaGVU2yMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/swap.vue").then(m => m.default || m)
  },
  {
    name: termsk0lhCrK4gIMeta?.name ?? "terms",
    path: termsk0lhCrK4gIMeta?.path ?? "/terms",
    meta: termsk0lhCrK4gIMeta || {},
    alias: termsk0lhCrK4gIMeta?.alias || [],
    redirect: termsk0lhCrK4gIMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: trade_45and_45earnAiOg9smCnYMeta?.name ?? "trade-and-earn",
    path: trade_45and_45earnAiOg9smCnYMeta?.path ?? "/trade-and-earn",
    meta: trade_45and_45earnAiOg9smCnYMeta || {},
    alias: trade_45and_45earnAiOg9smCnYMeta?.alias || [],
    redirect: trade_45and_45earnAiOg9smCnYMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/trade-and-earn.vue").then(m => m.default || m)
  },
  {
    name: liquidity_45botszbBMafbZdVMeta?.name ?? "trading-bots-liquidity-bots",
    path: liquidity_45botszbBMafbZdVMeta?.path ?? "/trading-bots/liquidity-bots",
    meta: liquidity_45botszbBMafbZdVMeta || {},
    alias: liquidity_45botszbBMafbZdVMeta?.alias || [],
    redirect: liquidity_45botszbBMafbZdVMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/trading-bots/liquidity-bots.vue").then(m => m.default || m),
    children: [
  {
    name: historyALp1GbSt3vMeta?.name ?? "trading-bots-liquidity-bots-spot-history",
    path: historyALp1GbSt3vMeta?.path ?? "spot/history",
    meta: historyALp1GbSt3vMeta || {},
    alias: historyALp1GbSt3vMeta?.alias || [],
    redirect: historyALp1GbSt3vMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/trading-bots/liquidity-bots/spot/history.vue").then(m => m.default || m)
  },
  {
    name: indexEPVPF9SsyqMeta?.name ?? "trading-bots-liquidity-bots-spot",
    path: indexEPVPF9SsyqMeta?.path ?? "spot",
    meta: indexEPVPF9SsyqMeta || {},
    alias: indexEPVPF9SsyqMeta?.alias || [],
    redirect: indexEPVPF9SsyqMeta?.redirect,
    component: () => import("/home/techstaack/inj/injective-dex/pages/trading-bots/liquidity-bots/spot/index.vue").then(m => m.default || m)
  }
]
  }
]