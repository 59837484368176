import validate from "/home/techstaack/inj/injective-dex/node_modules/nuxt/dist/pages/runtime/validate.js";
import dev_45mode_45global from "/home/techstaack/inj/injective-dex/middleware/dev-mode.global.ts";
import maintenance_45global from "/home/techstaack/inj/injective-dex/middleware/maintenance.global.ts";
import trailing_45slash_45global from "/home/techstaack/inj/injective-dex/middleware/trailingSlash.global.ts";
import manifest_45route_45rule from "/home/techstaack/inj/injective-dex/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  dev_45mode_45global,
  maintenance_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  connected: () => import("/home/techstaack/inj/injective-dex/middleware/connected.ts"),
  orderbook: () => import("/home/techstaack/inj/injective-dex/middleware/orderbook.ts"),
  swap: () => import("/home/techstaack/inj/injective-dex/middleware/swap.ts")
}